.flex{
    display: flex;
    justify-content: space-between;
    .w50{
        width: 50%;
    }
    .text{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        h1{
            font-size: 60px;
        }
        .ctaPrincipal{
            display: flex;
            justify-content: space-between;
            padding-top: 40px;
            button{
                width: 222px;
                height: 60px;
                background-color: #8DCCE2;
                color: #02091C;
                padding: 14px 24px;
                border-radius: 100px;
                font-size: 16px;
                font-family: 'Inter';
                border: 0;
                box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
                transition: all 0.3s ease 0s;
            }
            button:hover {
                background-color: #24acdd;
                box-shadow: 0px 15px 20px rgb(36, 172, 221, 0.4);
                transform: translateY(-7px);
              }
              
              button:active {
                transform: translateY(-1px);
              }
            .clientes{
                width: 247px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #ccc;
                border-right: 0;
                border-left: 0;
                padding: 0 10px;
                img{
                    height: 30px;
                    width: 100px;
                }
                .textClientes{
                    h1{
                        font-size: 14px;
                        text-align: center;
                    }
                    p{
                        text-align: center;
                        font-size: 10px;
                    }
                }
            }
        }
    }
    img{
        width: 100%;;
    }
    
}

@media (max-width:1024px){
    .flex{
        margin-top: 100px;
        .w50.textPrincipal{
            width: 100%;
            .text{
                h1{
                    font-size: 50px;
                }
                .ctaPrincipal{
                    justify-content: left;
                    button{
                        margin-right: 40px;
                    }
                }
            }
        }
        .image{
            display: none;
        }
    }
}

@media (max-width:490px){
    .flex{
        text-align: center;
        .text{
            h1{
                font-size: 38px !important;
            }
            .ctaPrincipal{
                flex-direction: column ;
                button{
                    margin: 0 0 30px 0!important;
                    width: 247px;
                    margin-bottom: 40px;
                }
                .clientes{
                    margin: 0 auto;
                    h1{
                        font-size: 16px !important;
                    }
                    p{
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}