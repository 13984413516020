.flex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .textFlex1{
        width: 100%;
        h1{
            font-size: 50px;
        }
    }
    .card{
        &.w40{
            width: 44%;
        }
        &.w60{
            width: 54%;
        }
        background-color: #01050E;
        display: flex;
        border-radius: 20px;
        .textCard{
            padding: 60px 0 60px 35px;
            h1{
                font-size: 28px;
                margin-bottom: 30px;
            }
            p{
                font-size: 16px;
                font-family: 'Inter';
                opacity: 0.8;
            }
        }
        .imgCard{
            padding: 0 35px 0 0;
            img{
                width: 140px;
            }
    
        }
    }
}

@media (max-width:1024px){
    .flex{
        flex-direction: column;
        margin-bottom: 0;
        .textFlex1{
            margin-bottom: 80px;
        }
        .card{
            margin-bottom: 20px;
            &.w40{
                width: 100% !important;
            }
            &.w60{
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 600px){
    .textFlex1{
        h1{
            font-size: 38px !important;
        }
    }
    .card{
        padding: 60px 20px;
        .textCard{
            width: 100%;
            padding: 0 !important;
            h1{
                font-size: 24px !important;
            }
            p{
                font-size: 14px !important;
            }
        }
        .imgCard{
            display: none;
            padding: 0 !important; 
        }
    }
}

@media (max-width: 390px){
    .textFlex1{
        h1{
            font-size: 28px !important;
        }
    }
}