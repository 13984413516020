.formulario{
    background-image: url(../../images/linhas.png);
    background-size: contain;
    background-color: #01050E;
    border-radius: 20px;
    width: 100%;
    padding: 100px 60px;
    display: flex;
    margin-bottom: 100px;
    .textForm{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        h1{
            font-size: 38px;
        }
        p{
            font-size: 16px;
            font-family: 'Inter';
        }
    }
    form{
        padding: 0 0 0 40px;
        width: 50%;
        input{
            width: 100%;
            height: 30px;
            background: none;
            border: 0;
            border-bottom: 2px solid white;
            margin-bottom: 10px;
            outline: 0;
        }
        input::placeholder {
            color: white;
            opacity: 1; /* Firefox */
          }
        textarea{
            display: inline-block;
            resize: none;
            width: 100%;
            height: 30px;
            background: none;
            outline: 0;
            border: 0;
            border-bottom: 2px solid white;
            margin-bottom: 10px;
        }
        textarea::placeholder {
            color: white;
            opacity: 1; /* Firefox */
          }
        button{
            width: 100%;
            height: 40px;
            background-color: #8DCCE2;
            color: #02091C;
            padding: 10px 24px;
            border-radius: 10px;
            font-family: 'Inter';
            font-size: 18px;
            border: 0;
            box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
            transition: all 0.3s ease 0s;
        }
        button:hover {
            background-color: #24acdd;
            box-shadow: 0px 10px 20px rgb(36, 172, 221, 0.4);
          }
          
          button:active {
            transform: translateY(-1px);
          }
    }
}

@media (max-width:1024px){
    .formulario{
        background-image: url(../../images/linhas.png);
        background-repeat: no-repeat;
        background-size: cover !important;
        -webkit-box-shadow: 0px 0px 8px 1px rgba(255,255,255,0.7);
        -moz-box-shadow: 0px 0px 8px 1px rgba(255,255,255,0.7);
        box-shadow: 0px 0px 8px 1px rgba(255,255,255,0.7);
        flex-direction: column;
        padding: 100px 60px;
        background-size: contain;
        .textForm{
            width: 100%;
            h1{
                margin-bottom: 30px;
            }

        }
        form{
            margin-top: 30px;
            padding: 0;
            width: 100%;
        }
    }
}

@media (max-width:390px){
    .formulario{
        padding: 80px 20px;
        background-image: url(../../images/linhasmob.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .textForm{
            h1{
                font-size: 24px;
                margin-bottom: 30px;
            }
            p{
                font-size: 14px;
            }
        }
        form{
            button{
                font-size: 12px;
            }
        }
    }
}