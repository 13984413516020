.footer{
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    padding-top: 40px;
    padding-bottom: 40px;
    .boxFooter{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .boxImg{
        width: 50%;
        img{
            width: 200px;
            height: 60px;
        }
    }
    .boxImgMob{
        display: none;
    }
    .boxIcons{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
        .circle{
            background: black;
            margin-left: 20px;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
                width: 20px;
            }
        }
    }
    .instagram:hover{
        background-color: #d62976;
        transition-duration: .3s;
    }
    .linkedin:hover{
        background-color: #0072b1;
        transition-duration: .3s;
    }
    .facebook:hover{
        background-color: #3b5998;
        transition-duration: .3s;
    }
    .behance:hover{
        background-color: #053eff;
        transition-duration: .3s;
    }
 }
 p{
    margin-top: 30px;
    width: 100%;
    text-align: center;
    font-size: 12px;
 }
}

@media (max-width:490px){
    .boxImg{
        display: none;
    }
    .boxImgMob{
        display: block !important;
        width: 30% !important;
        img{
            width: 60px;
            height: 60px;
        }
    }
    .boxIcons{
        width: 70% !important;
    }
}