.depoimentos{
    margin-bottom: 100px;
    h1{
        font-size: 50px;
        margin-bottom: 100px;
    }
    .boxImg{
       padding: 20px;
        img{
            width: 100%;
        }
    }
}

@media (max-width: 1024px){
    .boxImg{
        height: auto;
        max-width:100% !important;
        img{
            width: 100% !important;
            height: 100%;
            padding: 0 40px;
        }
    }
}

@media (max-width: 490px){
    .depoimentos{
        margin-bottom: 100px;
        h1{
            font-size: 38px;
            margin-bottom: 50px;
        }
        .boxImg{
           padding: 20px;
            img{
                padding: 0;
            }
        }
    }
}
