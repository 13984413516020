.flex{
    display: flex;
    margin-top: 150px;
    margin-bottom: 100px;
    height: 400px;
    justify-content: space-between;
    flex-wrap: nowrap;
    .text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 33%;
        h1{
            font-size: 50px;
        }
        .box1{
            display: flex;
            background-color: #8DCCE2;
            justify-content: space-between;
            align-items: center;
            padding: 15px 10px;
            border-radius: 15px;
            .circle{
                display: flex;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                background-color: #000;
                padding: 20px;
                border-radius: 50%;
            }
            .textBox1{
                padding: 0 15px;
                
                h1{
                    font-size: 22px;
                    font-family: 'Inter';
                    color: #02091C;
                }
                p{
                    color: #02091C;
                    font-family: 'Inter';
                    font-size: 15px;
                    margin-top: 10px;
                }
            }
        }
    }
    .midBox{
        display: flex;
        align-items: end;
        width: 33%;
  
        height: auto;
        img{
            width: 100%;
            max-width: 395px;
            height: 70%;
        }
    }
    .midBoxTablet{
        display: none;
    }
    .midBoxMobile{
        display: none;
    }
    .box3{
        width: 33%;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #01050E;
        padding: 25px 20px;
        border-radius: 20px;
        .topBox{
            display: flex;
            justify-content: space-between;
            .textBox3{
                text-align: center;
                h1{
                    font-size: 20px;
                }
                p{
                    font-size: 12px;
                }
            }
        }
        h1{
            font-size: 28px;
        }
        button{
            width: 170px;
            height: 50px;
            background-color: #8DCCE2;
            color: #02091C;
            padding: 16px 24px;
            border-radius: 100px;
            font-size: 14px;
            font-family: 'Inter';
            border: 0;
            svg{
                path{
                    color: #02091C;
                }
            }
            box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
            transition: all 0.3s ease 0s;
        }
        button:hover {
            background-color: #24acdd;
            box-shadow: 0px 15px 20px rgb(36, 172, 221, 0.4);
            transform: translateY(-7px);
          }
          
          button:active {
            transform: translateY(-1px);
          }
    }
}

@media (max-width:1024px){
    .flex{
        height: auto;
        flex-wrap: wrap !important;
        .text{
            width: 100% !important;
            h1{
                margin-bottom: 80px;
                font-size: 38px;
            }
            .box1{
                justify-content: left;
                .textBox1{
                    h1{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .midBox{
            display: none !important;
        }
        .midBoxTablet{
            display: block !important;
            width: 100% !important;
            margin: 20px 0;
            img{
                width: 100%;
            };
        }
        .box3{
            width: 100%;
        }
    }
}

@media (max-width: 390px){
    .flex{
        margin-top: 80px;
        .text{
            .box1{
                justify-content: left;
                .textBox1{
                    h1{
                        font-size: 16px;
                    }
                    p{font-size: 12px;}
                }
            }
        }
        .midBoxTablet{
            display: none !important;
        }

        .midBoxMobile{
            display: block !important;
            width: 100% !important;
            margin: 20px 0;
            img{
                width: 100%;
                max-height: 400px
            };
        }
        .box3{
            width: 100%;
            .topBox{
                .textBox3{
                    img{
                        height: auto;
                        max-width: 140px;
                    }
                    h1{font-size: 22px !important;}
                    p{font-size: 14px !important;}
                }
            }
        }
}
    }