.flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    img.logotipo{
        width: 60px;
        height: 60px;
    }
    .logomarca{
        width: 120px;
        height: 40px;
    }
    button{
        width: 132px;
        height: 42px;
        font-size: 12px;
        font-family: 'Inter';
        background-color: transparent;
        color: #8DCCE2;
        border: solid 2px #8DCCE2;
        padding: 14px 24px;
        border-radius: 100px;
        transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
        position: relative;
    }

    button::before{
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        border-radius: inherit;
        scale: 0;
        z-index: -1;
        background-color: #8DCCE2;
        transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }

      button:hover::before {
        scale: 1.1;
      }
      
      button:hover {
        color: #000;
        scale: 1.1;
        box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
      }
      
      button:active {
        scale: 1;
      }
}

@media (max-width:490px){

    .logomarca{
        display: none;
    }

  }