.box{
    border: 1px solid #ccc;
    border-right: 0;
    border-left: 0;
    padding: 20px 0;
    margin: 100px 0 450px 0;
        img{
        height: 23px;
        object-fit: contain;
        }
        p{
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            padding: 0 60px;
        }
    
}

@media (max-width: 1024px){
    .box{
        p{
            font-size: 20px;
            padding: 0 30px;
        }
        img{
            height: 18px;
        }
    }
}