/* assets/fonts.css */

* {
  box-sizing: border-box;
  font-family: 'Unbounded';
  color: #ffffff;
}

@font-face{
  font-family: Unbounded;
  src: url(../public/Unbounded-Regular.ttf);
}

@font-face{
  font-family: Inter;
  src: url(../public/Inter-Regular.ttf);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  background: linear-gradient(-45deg, #000,#000, #172849, #177E81);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  }
  
  @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }


ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}





button{
  cursor: pointer;
}



.container{
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 1%;
}
.w60{
  width: 60%;
}
.w50{
  width: 50%;
}
.w40{
  width: 40%;
}

.center .slick-current{
  transform: scale(1.1);
}

.center .slick-dots button::before{
  display: none;
}

.center ul.slick-dots{
  position: absolute;
 bottom: -50px;;
}

.center .slick-dots li{
  display: inline-block;
  background-color: #fff;
  opacity: 0.6;
  border-radius: 10px;

}

.center .slick-dots li.slick-active{
  background: white;
  opacity: 1;
  margin: 10px;
  width: 40px;
  transform: scale(1.1);
}

@media (max-width:1024px){
  .center .slick-current{
    transform: scale(1.2) !important;
    padding: 0 20px;
  }
}





