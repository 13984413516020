.boxsobre{
    height: 200px;  
    display: flex;
    align-items: end;
    margin-top: 350px;
    .back{
        background-color: #8DCCE2;
        height: 450px;
        width: 100%;
        padding: 80px 0 80px 0;
        .wrap{
            max-width: 1240px;
            margin: 0 auto;
            padding: 0 1%;
            display: flex;
            .image{
                width: 50%;
                height: 200px;
                img{
                    position: relative;
                    top: -100%;
                    max-width: 90%;
                }
            }
            .text{
                width: 50%;
                h1{
                    color: #02091C;
                    font-size: 38px;
                }
                p{
                    color: #02091C;
                    opacity: 0.6;
                    font-size: 16px;
                    font-family: 'Inter';
                    margin-top: 15px;
                }
                button{
                    width: 287px;
                    height: 65px;
                    background-color: #02091C;
                    color: #FFF;
                    padding: 14px 40px;
                    border-radius: 100px;
                    font-size: 14px;
                    font-family: 'Inter';
                    border: 0;
                    margin-top: 20px;
                    transition: all 0.3s ease 0s;
                }
                button:hover {
                    background-color: #02091C;
                    box-shadow: 0px 10px 20px rgba(2, 9, 28,0.7);
                    transform: translateY(-7px);
                  }
                  
                  button:active {
                    transform: translateY(-1px);
                  }
            }
        }
    }
}

@media (max-width:1024px){
    .boxsobre{
        margin-top: 750px !important;
    }
    .back{
        height: 600px !important;
        display: flex;
        align-items: flex-end;
        .wrap{
            flex-direction: column;
            flex-wrap: wrap;
            div.image{
                width: 100% !important;
                text-align: center;
                position: relative;
                max-height: 20px;
                img{
                    width: 100%;
                    max-width: 500px !important;
                    height: auto;
                    position: absolute !important;
                    transform: translate(-50%,-100%);
                }
            }
            div.text{
                width: 100% !important;
                position: relative;
                top: -10% !important;
                h1{
                    font-size: 28px !important;
                }
            }
        }
    }
}

@media (max-width: 490px){
    .boxsobre{
        margin-top: 700px !important;
    }
}

