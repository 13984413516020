.flex{
    margin-top: 150px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    .text{
        width: 50%;
        h1{
            font-size: 50px;
        }
        .boxText{
            display: flex;
            margin-top: 100px;
            .icons{
                display: flex;
                flex-direction: column;
                align-items: center;
                .line{
                    height: 90px;
                    border-left: dotted 4px white;
               
                }
            }
            .textContent{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 20px 40px;
                p{
                    font-size: 20px;
                    font-family: 'Inter';
                    font-weight: bold;
                }
            }
        }
    }
    .image{
        width: 50%;
        img{
            width: 100%;
        }
    }
}

@media (max-width:1024px){
    .flex{
        flex-direction: column;
        .text{
            width: 100%;
        }
        .image{
            display: none;
        }
    }
}

@media (max-width: 490px){
    .flex{
        margin-top: 80px;
        margin-bottom: 100px;
        .text{
            h1{
                font-size: 38px !important;
            }
            .boxText{
                .icons{
                    img{
                        height: 60px;
                    }
                }
                .textContent{
                    padding: 10px;
                    p{font-size: 16px !important;}
                }
            }
        }
    }
}